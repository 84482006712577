import * as React from "react";
import type { HeadFC } from "gatsby";
import styled from "styled-components";

import { Container } from "../components/Grid";
import { Layout } from "../components/Layout";

const ScMain = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #020203;

  .post-full-content {
    background-color: transparent;
  }
`;

const LegalPage = () => {
  return (
    <Layout>
      <ScMain>
        <Container>
          <article className="post-full post no-image page no-image">
            <h1>Risk Disclosure Statement</h1>
            <section className="post-full-content">
              <div className="post-content">
                <p>
                  <br />
                  The terms used in this Risk Disclosure Statement shall have
                  the same meanings as in the{" "}
                  <a href="https://power.trade/terms/">Terms of Service</a>{" "}
                  except as otherwise provided herein.
                </p>
                <p>
                  Spot trading or leveraged trading in Virtual Assets, Futures,
                  Perpetual Swaps, Options and/or other Virtual Asset
                  Derivatives (“<strong>Trading</strong>”) carries a high level
                  of risk to your capital. Trading is not suitable for everyone
                  and may result in losses that are greater than your deposits.
                  You should only trade with money you can afford to lose.
                </p>
                <p>
                  This Risk Disclosure Statement (this “
                  <strong>Statement</strong>
                  ”) provides you with a non-exhaustive overview of the key
                  risks that you should take into account when deciding whether
                  to open an Account and purchase, sell and/or trade Virtual
                  Assets, Futures, Perpetual Swaps, Options and/or other Virtual
                  Asset Derivatives through the Services. This Statement does
                  not explain all of the risks involved in Trading or how the
                  risks relate to your personal circumstances. It is important
                  that you read and understand the relevant legal documentation
                  to fully be aware of the risks involved according to your
                  personal circumstances before deciding to open an Account and
                  access and/or use the Services. We recommend that you seek
                  independent advice if you are unsure.
                </p>
                <p>
                  In consideration of Power Fintech LLC ("<strong>we</strong>",
                  "<strong>our</strong>", "<strong>us</strong>", "
                  <strong>PowerTrade</strong>") agreeing to provide Services to
                  the Customer, the Customer acknowledges, understands and
                  agrees that:
                  <br />
                </p>
                <ol>
                  <li>
                    <strong>
                      <strong>
                        <strong>NO GUARANTEE OF PROFITS</strong>
                      </strong>
                    </strong>
                  </li>
                </ol>
                <p>
                  PowerTrade provides no guarantees of profit nor of avoiding
                  losses when Trading. Customer has received NO guarantee from
                  PowerTrade or from any PowerTrade Entities. Customers are
                  aware of the risks inherent in trading and are financially
                  able to bear such risks and withstand any losses incurred.
                  <br />
                </p>
                <p>
                  <strong>
                    2.{" "}
                    <strong>
                      <strong>
                        NO INVESTMENT, FINANCIAL, LEGAL, REGULATORY OR TAX
                        ADVICE
                      </strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Our Services are provided on an execution-only basis. You are
                  solely responsible for any decisions that you make in relation
                  to the Virtual Assets, Futures, Perpetual Swaps, Options
                  and/or other Virtual Asset Derivatives purchased, sold and/or
                  traded through our Services.
                </p>
                <p>
                  PowerTrade is not an investment, financial, legal or tax
                  advisor nor do we provide any investment, financial,
                  regulatory, tax or legal advice. In certain cases, we may
                  provide you with general factual information about the market
                  and our Services. Any information and analysis provided by us
                  is general in nature, and purely for informational purposes,
                  and does not take into account the Customer’s personal
                  objectives, financial situation, investment risk profile or
                  needs. Furthermore, you must not regard any of the information
                  that we provide to you as an investment recommendation or an
                  offer to make a transaction.
                  <br />
                </p>
                <p>
                  <strong>
                    3.{" "}
                    <strong>
                      <strong>APPROPRIATENESS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Do not invest with money or Virtual Assets you cannot afford
                  to lose. Trading carries a high degree of risk and due to
                  fluctuations in value, the Customer may not get back the
                  amount invested, or you may lose a substantial proportion or
                  all of your capital. Virtual Assets, Options, and Virtual
                  Assets Derivatives and are not suitable for everyone. You
                  should carefully consider whether you can afford to bear the
                  risks of loss involved in using the Services and, in
                  particular, trading Virtual Assets, Options, and Virtual
                  Assets Derivatives.
                </p>
                <p>
                  Virtual Assets, Futures, Perpetual Swaps, Options and/or other
                  Virtual Asset Derivatives are not suitable for an investor
                  seeking an income from their investments, as the income from
                  such investments may fluctuate in value in money terms.{" "}
                </p>
                <p>
                  If you decide to continue and open an Account and use the
                  Services, you are confirming that you are aware of and
                  understand the risks.
                  <br />
                </p>
                <p>
                  <strong>
                    4.{" "}
                    <strong>
                      <strong>THE TRADING PLATFORM</strong>
                    </strong>
                  </strong>
                  <br />
                  <br />
                  The Customer is warned, and the Customer agrees and
                  understands that when trading in an electronic platform he
                  assumes risk of financial loss which may be a consequence of
                  amongst other things: i) Failure of Customer’s devices,
                  software and poor quality of connection; ii) PowerTrade or
                  Customer’s hardware or software failure, malfunction or
                  misuse; iii) Improper work of Customer’s equipment; iv) Wrong
                  setting of Customer’s Terminal; and/or v) Delayed updates of
                  Customer’s Terminal.
                  <br />
                </p>
                <p>
                  <strong>
                    5.{" "}
                    <strong>
                      <strong>GENERAL TRADING RISKS</strong>
                    </strong>
                  </strong>
                  <br />
                  <br />
                  Virtual Asset prices are highly volatile and trading in
                  Virtual Assets is extremely risky. In addition, several
                  factors may affect market liquidity for a particular Virtual
                  Asset, such as regulatory activity, market manipulation, the
                  acts or omissions of the issuer of the Virtual Asset, or
                  unexplainable price volatility. VirtualAssets are
                  decentralized and non-regulated, which means that there is no
                  central bank that can take corrective measures to protect the
                  value of a Virtual Asset in a crisis or issue more Virtual
                  Assets. As a result, Virtual Currency trading involves a high
                  risk of loss of funds over a short period of time due to high
                  market volatility, execution issues and industry-specific
                  disruptive events, including, but not limited to,
                  discontinuation, regulatory bans and other malicious actors
                  within the Virtual Assets industry.
                </p>
                <p>
                  Virtual Asset Trading Platforms could provide incorrect,
                  delayed, or otherwise flawed data for a variety of reasons,
                  including as a result of software bugs and the limited
                  oversight on markets for Virtual Assets. Such poor data could
                  misinform the Customer trading strategies or engender
                  inaccurate price discovery mechanisms. Errors, fraud, and
                  other issues with Virtual Asset Trading Platforms have
                  resulted in extensive, irretrievable losses on multiple
                  occasions in the recent past. The underlying software and
                  mechanisms for the Virtual Asset Trading Platforms could
                  malfunction, executing trades for Customers at faulty prices
                  and adversely affecting the Customer investments. In general,
                  errors and unreviewable decisions by Virtual Asset Trading
                  Platforms could adversely impact the value of the investment
                  of the Customers.
                </p>
                <p>
                  It is important that you comprehend the risks associated with
                  trading on a market as fluctuations in the price of the
                  underlying spot market will have an effect on the
                  profitability of the trade.
                </p>
                <p>
                  When Trading, Slippage may occur. Slippage is the difference
                  between the expected price of a Transaction, and the price the
                  Transaction is actually executed at. Slippage often occurs
                  during periods of higher volatility (for example due to news
                  events) making an Order at a specific price impossible to
                  execute, when market orders are used, and also when large
                  Orders are executed when there may not be enough interest at
                  the desired price level to maintain the expected price of the
                  trade. Furthermore, prices displayed on the Trading Platform
                  are solely an indication of the executable rates and may not
                  reflect the actual executed or executable price of an order
                  due to issues related to latency in the prices provided and
                  connectivity issues. It is important that you comprehend that
                  when placing an order, your order may not be filled at the
                  quoted price.
                </p>
                <p>
                  By using the Services, you accept the risk of trading Virtual
                  Assets, Futures, Perpetual Swaps, Options and/or other Virtual
                  Asset Derivatives. In entering into any transaction, you
                  represent that you have been, are, and will be solely
                  responsible for making your own independent appraisal into the
                  risks of the transaction and the underlying Virtual Asset.{" "}
                  <br />
                </p>
                <p>
                  <strong>
                    6.{" "}
                    <strong>
                      <strong>LIQUIDITY RISKS</strong>
                    </strong>
                  </strong>
                  <br />
                  <br />
                  Trading in Virtual Assets, Futures, Perpetual Swaps, Options
                  and/or other Virtual Asset Derivatives is riskier, less liquid
                  and more volatile. The liquidity of the markets on the Trading
                  Platform will depend on, among other things, supply and demand
                  on the Trading Platform and the interest in these markets.
                  There is a risk that there may be limited volume and liquidity
                  which may result in delays in order execution, and
                  potentially, some orders may not be executed.
                  <br />
                </p>
                <p>
                  <strong>
                    7.{" "}
                    <strong>
                      <strong>VIRTUAL ASSET PRICING RISKS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  PowerTrade may use its own or a third-party methodology for
                  calculation of Virtual Asset prices, which may differ from
                  publicly-reported prices from other sources. The methodology
                  and the sources for calculating the Virtual Asset prices may
                  be subject to change at any time without notice in
                  PowerTrade’s sole and absolute discretion. The change of
                  methodology and sources for calculating Virtual Asset prices
                  may affect the published prices, and therefore, may affect the
                  profitability or losses of your trades. PowerTrade is not
                  responsible or liable for any direct or consequential losses a
                  Customer may incur as a result (directly or indirectly) of a
                  change in the methodology and the sources for calculating the
                  Virtual Asset prices.
                  <br />
                </p>
                <p>
                  <strong>
                    8.{" "}
                    <strong>
                      <strong>OPTIONS TRADING RISKS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  At Expiration Date, Options that are ITM will be automatically
                  closed out at intrinsic value, that is, in the case of Call
                  Options, the amount by which the closing price of the
                  specified Virtual Asset exceeds the Strike Price, and in the
                  case of Put Options, the amount by which the Strike Price
                  exceeds the closing price of the specified Virtual Assets. For
                  long Call and short Put positions, the closing price will be
                  the prevailing bid price of the underlying Virtual Asset at
                  Expiration Date; and for short Call and long Put positions,
                  the closing price will be the prevailing ask (offer) price of
                  the underlying Virtual Asset, as determined by PowerTrade in
                  its sole discretion. Options that are not ITM will expire
                  worthless.
                  <br />
                </p>
                <p>
                  <strong>
                    9.{" "}
                    <strong>
                      <strong>LEVERAGED TRADING RISKS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Financed Trading means that both profits and losses can be
                  magnified, and you could incur very large losses if your
                  position moves against you. You may even suffer losses that
                  exceed the amount you deposited as Margin and your failure to
                  maintain a sufficient amount of Margin may result in the
                  forced-liquidation of your positions.{" "}
                </p>
                <p>
                  Before you open a Financed Trading position, we require you to
                  deposit an initial Margin and, in order to keep a Transaction
                  open, you must ensure that the amount in your Margin Account
                  exceeds the Minimum Margin Requirement. This means that you
                  will be trading using ‘leverage’ or 'gearing' and this can
                  work for or against you; a small price movement in your favour
                  can result in a high return on the initial Margin placed for
                  the trade, but a small price movement against you may result
                  in substantial losses.
                </p>
                <p>
                  Financed Trading means you can secure a significantly larger
                  exposure to an underlying asset for a relatively small initial
                  Margin. However, the use of leverage magnifies the size of
                  your trade, which means your potential gain and your potential
                  loss are equally magnified. Therefore, you should closely
                  monitor all of your open positions to manage the risk of large
                  losses.
                </p>
                <p>
                  We will require you to ensure that the amount in your Margin
                  Account exceeds the Minimum Margin Requirement in order to
                  keep a Financed Trading position open. Therefore, if our price
                  moves against you, you may need to provide us with substantial
                  additional Margin, at short notice, to maintain your open
                  positions. If you do not do this, we will be entitled to
                  liquidate one or more or all of your trades. You will be
                  responsible for any losses incurred.{" "}
                </p>
                <p>
                  You should also be aware that under the Terms we are entitled,
                  at our sole discretion, to make a Margin Call, and that you
                  are required to satisfy any Margin Calls immediately, by any
                  applicable means in the time prescribed by us. If you do not
                  do this, we will be entitled to liquidate one, or more, or all
                  of your trades. <br />
                </p>
                <p>
                  <strong>
                    10.{" "}
                    <strong>
                      <strong>ACCOUNT WALLET RISKS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  You are responsible to use the correct blockchain address of
                  your virtual asset wallet address or addresses associated with
                  your Account (the “<strong>Account Wallet</strong>”) for any
                  deposit, or transfer, and that the address you use is
                  compatible with the Virtual Asset you are depositing, or
                  transferring. Any inaccuracy in an Account Wallet or in the
                  Virtual Asset that you attempt to transfer, may result in
                  total loss of the Virtual Asset concerned.{" "}
                </p>
                <p>
                  Furthermore, Virtual Assets transferred to or held in the
                  Account Wallet are not insured by any Government Agency or
                  public authority, and are not covered by any deposit
                  protection schemes. Furthermore, they are segregated from, and
                  may be commingled with, other Customers’ Virtual Assets.
                  PowerTrade does not have any trust, fiduciary, or other
                  custodial relationship with you. Your Wallet Account balances
                  (including both deposits, trading profits or any other Virtual
                  Assets kept in your Account Wallet) are not insured, and
                  therefore they are at a risk of total loss, from, among others
                  things, security breaches (whether in respect of your accounts
                  specifically or the Services), electronic, technological or
                  systems failures, and PowerTrade insolvency or bankruptcy, or
                  equivalent formal proceedings.
                  <br />
                  <br />
                </p>
                <p>
                  <strong>
                    11.{" "}
                    <strong>
                      <strong>OPERATIONAL RISKS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Operational risks with PowerTrade on your computer are
                  inherent in every transaction. For example, disruptions in
                  PowerTrade's operational processes such as communications,
                  computers, computer or mobile networks or external events may
                  lead to delays in the execution and settlement of a
                  transaction. PowerTrade does not accept or bear any liability
                  whatsoever in relation to the operational processes of
                  PowerTrade, except to the extent that it is caused by the
                  fraud, negligence or dishonesty by PowerTrade.
                </p>
                <p>
                  In connection with the use of computer equipment and data, the
                  Customer bears certain risks amongst other risks, in which
                  cases PowerTrade has no liability of any resulting loss,
                  including but not limited to i) Power cut of the equipment on
                  the side of the Customer or the provider; ii) wrong or
                  inconsistent with requirements settings of the Customer
                  Terminal; or iii) Untimely update of the Customer Terminal.
                  The Customer may suffer financial losses caused by the
                  materialization of these risks, PowerTrade does have no
                  responsibility or liability in the case of such a risk
                  materializing and the Customer shall be responsible for all
                  related losses he may suffer.
                  <br />
                </p>
                <p>
                  <strong>
                    12.{" "}
                    <strong>
                      <strong>REGULATORY RISKS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Neither PowerTrade nor the Services are regulated or
                  supervised by any regulatory body. You understand your
                  responsibility to make sure that you comply with any and all
                  laws, regulations, directives, restrictions of your place or
                  places of residence before using our Services. Our Services
                  shall not be accessed or used, and we do not offer or solicit
                  the access or use of the Services, in jurisdictions in which
                  the Services are not permitted (including, without limitation,
                  Prohibited Jurisdictions).{" "}
                </p>
                <p>
                  Regulation of Virtual Assets, Virtual Asset Derivatives,
                  Virtual Asset service providers, and blockchain technologies,
                  which include PowerTrade Services lack uniformity and are
                  unsettled in many jurisdictions. These regulations are
                  evolving rapidly, are subject to significant variation among
                  international jurisdictions and are generally subject to
                  significant uncertainty. PowerTrade may receive queries,
                  notices, warnings, requests or rulings from one or more
                  regulatory authorities from time to time, or may even be
                  ordered to suspend or discontinue any action in connection
                  with the Services. There is no guarantee that new laws or
                  regulations or new enforcement or interpretation of current
                  laws or regulations will not adversely affect Virtual Assets,
                  Options or Virtual Assets Derivatives, the Services, the price
                  and liquidity of the Virtual Assets, Options or Virtual Assets
                  Derivatives traded through the Trading Platform. Furthermore,
                  PowerTrade may limit the availability of the Services to
                  certain persons based on their citizenship, residence or
                  location from engaging in any transactions, which may also
                  negatively affect the aforementioned price and liquidity of
                  the Virtual Assets, Options or Virtual Assets Derivatives
                  traded through the Trading Platform.
                  <br />
                </p>
                <p>
                  <strong>
                    13.{" "}
                    <strong>
                      <strong>CYBERSECURITY RISKS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  There have been many documented incidents of thefts and
                  attempted thefts of Virtual Assets and Virtual Assets Trading
                  Platforms. PowerTrade may hold large amounts of Virtual
                  Assets, including Customers’ Assets. This may make such
                  entities the target of cryptocurrency thieves and scammers.
                  Due to the rapidly evolving nature of cryptocurrency, there
                  likely will always be a risk of vulnerability to theft even
                  with adherence to security best practices. There can be no
                  guarantee that PowerTrade will not be a victim of Virtual
                  Asset theft, the result of which may adversely affect the
                  Customers, including a loss of the Virtual Assets held in
                  their Account Wallet.
                </p>
                <p>
                  Furthermore, cyberattacks, including but not limited to
                  malware attacks, denial of service attacks, coordinated
                  attacks and account takeovers may negatively affect the
                  Services, and the value of Virtual Assets, Options or Virtual
                  Assets Derivatives traded through the Services. Cyberattacks
                  may also lead to unauthorized access to Accounts or
                  PowerTrade’s systems for misappropriation of assets or
                  sensitive information, corrupting data, or causing operational
                  disruption. Cyberattacks to third party service providers
                  could substantially affect the Services.
                </p>
                <p>
                  You are responsible for maintaining adequate security and
                  control of any and all user IDs, passwords that you use to
                  access the Services. You are strongly encouraged to use
                  two-factor authentication in accessing the Website and the
                  Services, and may adjust their security features in their user
                  settings. If you suspect or become aware of any unauthorized
                  use of your Account, you should notify PowerTrade immediately.
                  PowerTrade assumes no liability for any loss or damage arising
                  from the access and/or use of your Account by you or any third
                  party with or without your authorization.
                  <br />
                </p>
                <p>
                  <strong>
                    14.{" "}
                    <strong>
                      <strong>INSOLVENCY RISK</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Our insolvency or default may lead to positions being
                  liquidated or closed out without your consent. In certain
                  circumstances, you may not recover the Virtual Assets held in
                  your Account. <br />
                </p>
                <p>
                  <strong>
                    15.{" "}
                    <strong>
                      <strong>FISCAL RISK</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  There is a risk that the Customer’s trades through the
                  Services may be or become subject to tax and/or any other duty
                  for example, due to changes in legislation or Customer's
                  personal circumstances. PowerTrade does not offer tax advice,
                  and the Customer is responsible for any taxes and/or any other
                  duty which may accrue in respect of his trades through the
                  Services.
                  <br />
                </p>
                <p>
                  Furthermore, tax laws and regulations are highly complex and
                  subject to interpretation, especially when cross-border
                  transactions and multiple tax jurisdictions are involved.
                  Consequently, PowerTrade are subject to changing tax laws,
                  treaties and regulations. If any tax authority successfully
                  challenges the operational structure of PowerTrade, or
                  PowerTrade loses a material tax dispute, the PowerTrade tax
                  liabilities could increase substantially. This could cause
                  PowerTrade financial resources to be constrained or impaired.
                  This in turn could negatively affect the availability and/or
                  provision of the Services.
                  <br />
                </p>
                <p>
                  <strong>
                    16.{" "}
                    <strong>
                      <strong>ACCURACY OF INFORMATION</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Although we use our best efforts to keep the information
                  related to the Services as accurate, complete and up to date
                  as possible, there is a risk that the information related to
                  the Services may not be accurate, complete or up to date.
                  <br />
                </p>
                <p>
                  <strong>
                    17.{" "}
                    <strong>
                      <strong>FORCE MAJEURE EVENTS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  In case of a Force Majeure Event, PowerTrade may not be in a
                  position to arrange for the execution of Customer Orders or
                  fulfil its obligations under the Terms of Service with the
                  Customer. As a result the Customer may suffer financial loss.
                  PowerTrade will not be liable or have any responsibility for
                  any type of loss or damage arising out of any failure,
                  interruption, or delay in performing its obligations under the
                  Terms where such failure, interruption or delay is due to a
                  Force Majeure event.
                  <br />
                </p>
                <p>
                  <strong>18. SCHEDULE OF FEES</strong>
                </p>
                <p>
                  Our schedule of fees is published on our Website, and can be
                  found <a href="https://power.trade/fees/">here</a>. Minimum
                  charges can be relevant for smaller trade sizes and there are
                  also charges associated with Financed Transactions.
                  Furthermore, our Schedule of Fees may change at any time, at
                  our discretion.
                </p>
                <p>
                  <em>v1.0</em>
                </p>
              </div>
            </section>
          </article>
        </Container>
      </ScMain>
    </Layout>
  );
};

export default LegalPage;

export const Head: HeadFC = () => <title>Cookie Policy</title>;
